<template>
    <div>
        <NavPackages/>
        <ModalApplePay/>
        <div class="container-packages-v3">
            <span class="packages-modal-title mb-title-packages">Affordable plans. Fit for your needs</span>
            <div class="packages-title-selection-method">
                <span :class="isMonthly ? 'selected-packages-color' : ''" class="cursor-pointer" @click="payMonthly()">Pay monthly</span>
                / 
                <span :class="!isMonthly ? 'selected-packages-color' : ''" class="cursor-pointer" @click="payYearly()">Pay annually</span>
                <img v-if="isMonthly" src="@/assets/images/icon/annually-spark-grey.svg" alt="">
                <img v-if="!isMonthly" src="@/assets/images/icon/annually-spark.svg" alt="">
            </div>
            <div class="list-modal-packages">
            <!-- {{ currentPackage }} -->
            <!-- {{ listPackages }} -->
                <div class="card-modal-packages" v-for="(planPackage, index) in listPackages" :key="index">
                    <!-- {{planPackage}} -->
                    <span class="modal-title-packages-v3 mb-3">{{ planPackage.name_package }}</span>
                    <span class="size-packages-v3 mb-3">{{ planPackage.basic ? niceBytes(planPackage.basic.storage) : '' }}</span>
                    <button
                        v-if="currentPackage"
                        :class="planPackage.basic.storage <= currentPackage.basic.storage ? 'my-3 btn-modal-packages-disabled-v3' : 'my-3 btn-modal-packages-v3'"
                        :disabled="planPackage.basic.storage <= currentPackage.basic.storage ? true : false"
                        @click="getPackage(planPackage._id)"
                    >
                        <span>{{ currentPackage.name_package.toLowerCase() == planPackage.name_package.toLowerCase() ? 'Current Plan' : 'Buy Now' }}</span>
                    </button>
                    <button
                        v-else
                        class="my-3 btn-modal-packages-v3"
                        @click="planPackage.name_package.toLowerCase() == 'free' || 
                        planPackage.name_package.toLowerCase() == 'basic' ?
                        goSignUp() : getPackage(planPackage._id)"
                    >
                        <span>{{ planPackage.name_package.toLowerCase() == 'free' || planPackage.name_package.toLowerCase() == 'basic' ? 'Try For Free' : 'Buy Now' }}</span>
                    </button>
                    <div class="modal-facility-packages-v3">
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>{{ planPackage.workgroup.max_workgroup }} workgroups</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>{{ planPackage.workgroup.max_participants }} workgroup participants</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>{{ planPackage.max_uploaded }} max. file size per upload</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>Encryption</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>Real-time collaboration</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>File recovery</span>
                        </div>
                        <div class="details-packages-v3">
                            <img src="@/assets/images/icon/bi_check.svg" alt="">
                            <span>Desktop and mobile access</span>
                        </div>
                    </div>
                    <div v-if="planPackage.name_package.toLowerCase() == 'free' || planPackage.name_package.toLowerCase() == 'basic'">
                        <span class="modal-price-packages-v3">Free</span>
                    </div>
                    <div v-else>
                        <div v-if="!isMonthly">
                            <span class="modal-discount-price-packages-v3">${{ planPackage.price_month }}</span>
                            <span class="modal-price-packages-v3">${{ divided12(planPackage.price_year) }}<span>/month</span></span>
                        </div>
                        <span v-else class="modal-price-packages-v3">${{ planPackage.price_month }}<span>/month</span></span>
                    </div>
                </div>
            </div>
            <!-- <pre>
                {{enterpriseList}}
            </pre> -->
            <div class="list-modal-enterprise">
                <div v-for="enterprise in enterpriseList" :key="enterprise._id" class="container-class-enterprise">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex flex-column">
                                <span class="enterprise-main-title mb-3">{{enterprise.name_package}}</span>
                                <span class="mb-3 enterprise-text-storage">{{niceBytes(enterprise.storage)}}</span>
                                <span class="mb-3 enterprise-text-desc">Share your {{niceBytes(enterprise.storage)}} storage with the people within your organization or enterprise.</span>
                                <span class="mb-3 enterprise-text-price">${{enterprise.price_month}}<span>/month</span></span>
                                <!-- <span class="mb-3 enterprise-text-price">${{!isMonthly ? enterprise.price_year : enterprise.price_month}}<span>/{{!isMonthly ? 'year' : 'month'}}</span></span> -->
                                <button :disabled="currentEnterprise != null" @click="getPackageEnterprise(enterprise._id)" class="mt-3" :class="currentEnterprise == null ? 'btn-get-enterprise' : 'btn-disabled-get-enterprise'">Buy Now</button>
                            </div>
                        </div>    
                        <div class="col-md-6">
                            <div class="d-flex flex-column enterprise-facility">
                                <span class="mb-4">Everything in Premium, plus:</span>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Free for up to {{enterprise.free_user}} seats, then ${{enterprise.price_user}}/seat/month</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Unlimited workgroups</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Unlimited workgroup participants</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Storage sharing and control</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Admin dashboard</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility mb-4">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Customized logo and colors</span>
                                </div>
                                <div class="d-flex align-items-center enterprise-facility">
                                    <img src="@/assets/images/icon/bi_check.svg" alt="">
                                    <span>Custom domain</span>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="footer-packages-v3">
                <div class="footer-description">
                    <img crossorigin="anonymous" :src="this.$logo" alt="" class="mb-3">
                    <span class="footer-description-text">A secure and free cloud storage for collaboration</span>
                    <span class="footer-description-text-res text-center mb-3">A secure and free cloud storage for collaboration</span>
                </div>
                <div class="footer-support-v3">
                    <span class="mb-3 title-footer-support">Product</span>
                    <a href="https://jaybod.com/features.html" target="_blank" class="my-3 list-footer-support">Features</a>
                    <a href="/packages" class="my-3 list-footer-support">Pricing</a>
                    <a href="https://jaybod.com/enterprise.html" target="_blank" class="my-3 list-footer-support">Enterprise</a>
                </div>
                <div class="footer-support-v3">
                    <span class="mb-3 title-footer-support">Support</span>
                    <a href="https://blog.jaybod.com/" target="_blank" class="my-3 list-footer-support">Blog</a>
                    <a href="https://jaybod.com/helpcenter" target="_blank" class="my-3 list-footer-support">Help Center</a>
                    <a href="https://jaybod.com/contact-us" target="_blank" class="my-3 list-footer-support">Contact Us</a>
                    <a href="https://jaybod.com/terms-service" target="_blank" class="my-3 list-footer-support">Terms & Privacy</a>
                </div>
                <div class="footer-support-v3">
                    <span class="mb-3 title-footer-support">Follow Us</span>
                    <a href="https://www.facebook.com/jaybodstorageofficial" target="_blank" class="my-3 list-footer-support">Facebook</a>
                    <a href="https://www.instagram.com/jaybodstorage/" target="_blank" class="my-3 list-footer-support">Instagram</a>
                    <a href="https://www.linkedin.com/company/desktopip/" target="_blank" class="mt-3 list-footer-support">LinkedIn</a>
                </div>
            </div>
            <span class="copyright-v3">© 2022 desktopIP Limited. All rights reserved.</span>
        </div>
    </div>
</template>

<script>
import NavPackages from '../../../components/nav-packages.vue'
import ModalApplePay from "../../../components/modalApplePay.vue";
export default {
    name: 'PackageV3',
    page: {
        title: "Compare Plans & Pricing",
        titleTemplate: '%s - Jaybod',
        meta: [
            { 
                name: 'description',
                content: 'A secure cloud storage with collaboration tools. Get more space with affordable price that fits your needs.'
            }
        ]
    },
    data() {
        return {
            isMonthly: true
        }
    },
    components: {
        NavPackages,
        ModalApplePay
    },
    created () {
        if(this.$route.query.hash != undefined) {
            this.$store.dispatch('auth/validateHash',this.$route.query.hash)
            .then(() => {
                this.$store.dispatch('packages/getPackages')
                this.$store.dispatch('enterprise/getListEnterprise')
                // .then(result => {
                    //     this.selected1 = result ? result[0] : ''
                //     this.selected2 = result.length > 1 ? result[1] : ''
                // })
            })
        }else{
            this.$store.dispatch('packages/getPackages')
            this.$store.dispatch('enterprise/getListEnterprise')
            // .then(result => {
            //     this.selected1 = result ? result[0] : result[0]
            //     this.selected2 = result.length > 1 ? result[1] : result[0]
            // })
        }
    },
    mounted() {
        if(this.$store.getters['auth/user'] != null){
            this.$store.dispatch("user/getLimit", null)
            if(this.myEnterprise == null){
                    this.$store.dispatch('enterprise/getMyEnterprise')
            }
        }
    },
    computed: {
        listPackages: {
            cache: false,
            get() {
                const data = this.$store.getters["packages/allPackages"]
                data.map(el => {
                    // el.basic.storage = this.niceBytes(el.basic.storage)
                    if (el.basic !== undefined) {
                        if (el.basic.max_upload !== 'unlimited') {
                        el.max_uploaded = this.niceBytes(el.basic.max_upload)
                        } else {
                        el.max_uploaded = this.upperCase(el.basic.max_upload)
                        }
                    }
                })
                return this.sortPackages(data)
            },
            set(newValue) {
                this.$store.commit("packages/SET_PACKAGES", newValue)
            },
        },
        isApplePay() {
            return this.$store.getters["user/isApplePay"]
        },
        enterpriseList() {
            return this.$store.getters["enterprise/listEnterprise"]
        },
        currentPackage() {
            return this.$store.getters["user/package"]
        },
        currentEnterprise() {
            return this.$store.getters['enterprise/myEnterprise']
        }
    },
    methods: {
        payMonthly() {
            this.isMonthly = true
        },
        payYearly() {
            this.isMonthly = false
        },
        goSignUp() {
            this.$router.push(`/signup`)
        },
        getPackage(id) {
            if(this.isApplePay == false){
                this.$store.commit('packages/SET_SELECTED_PRICE', this.isMonthly)
                this.$router.push(`/checkout/${id}`)
            } else {
                this.$bvModal.show('modal-apple-pay')
            }
        },
        getPackageEnterprise(id) {
            if(this.isApplePay == false){
                this.$store.commit('packages/SET_SELECTED_PRICE', true)
                this.$router.push(`/checkout/${id}`)
            } else {
                this.$bvModal.show('modal-apple-pay')
            }
        },
        niceBytes(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return 'n/a';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            if (i == 0) return bytes + ' ' + sizes[i];
            return (bytes / Math.pow(1024, i)).toFixed() + ' ' + sizes[i];
        },
        divided12(value) {
            var price = value / 12
            return price.toFixed(2)
        },
        upperCase(str) {
            let strIndex = str.split('')
            let arr = [0]
            for(let i = 0; i < arr.length; i++){
                if(strIndex[arr[i]]){
                strIndex[arr[i]] = strIndex[arr[i]].toUpperCase()
                }
            }
            return strIndex.join('')
        },
        sortPackages(value) {
            return value.slice().sort(function(a, b) {return a.basic.storage - b.basic.storage})
        }
    }
}
</script>

<style>
.container-packages-v3{
    background-color: #EFF3F5 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: calc(100vh - 100px); */
    height: fit-content;
    /* min-width: 100vw; */
    padding: 64px 72px 48px 72px;
}
.footer-packages-v3{
    width: 1200px;
    margin-top: 80px;
    padding: 0 72px 48px 72px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E9EAEA;
    margin-bottom: 48px;
}
.footer-description{
    display: flex;
    flex-direction: column;
    width: 40%;
}
.footer-description img{
    height: 35px;
    width: 141.21px;
}
.footer-description span{
    width: 300px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: -1px;
    color: #262A2C;
}
.footer-description-text{
    display: block;
}
.footer-description-text-res{
    display: none;
}
.footer-support-v3{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.title-footer-support{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #919394;
}
.list-footer-support{
    cursor: pointer;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #262A2C;
}
.list-footer-support:hover{
    color: #00AAFF;
}
.copyright-v3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 400;
    color: #262A2C;
}
@media (max-width: 767px) {
    .list-modal-packages{
        height: fit-content;
        width: fit-content;
        background-color: #FFFFFF;
        margin: 32px;
        border-radius: 32px;
        padding: 0 24px 16px 24px;
        display: flex;
        flex-wrap: wrap;
    }
    .container-packages-v3{
        padding: 24px;
    }
    .footer-packages-v3{
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .footer-description{
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-description-text{
        display: none;
    }
    .footer-description-text-res{
        display: block;
    }
    .footer-support-v3{
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title-footer-support{
        margin-top: 24px;
    }
}
</style>