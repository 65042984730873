<template>
  <b-modal id="modal-apple-pay" centered no-close-on-backdrop hide-header hide-footer>
    <div class="d-flex justify-content-start flex-column">
        <span class="title-modal-apple-pay mb-3">Account upgrade failed</span>
        <span class="title-sub-modal-apple-pay mb-3">Sorry, this account can’t be upgraded with another payment method. Please upgrade this account with Apple Pay.</span>
        <div class="d-flex justify-content-end align-items-center mt-3">
            <a href="https://jaybod.com/payments-and-billing" class="mr-3 d-flex align-items-center text-learn-apple-pay">Learn More</a>
            <button @click="close()" class="ml-3 btn-close-apple-modal">Got it</button>
        </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalApplePay',
  methods: {
    close() {
      this.$bvModal.hide('modal-apple-pay')
    }
  }
}
</script>

<style>
.title-modal-apple-pay{
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262A2C;
}
.title-sub-modal-apple-pay{
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #262A2C;
}
.btn-close-apple-modal{
    background: #00AAFF;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
}
.text-learn-apple-pay{
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #00AAFF;
}
</style>